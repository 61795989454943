exports.shouldUpdateScroll = ({
    routerProps: { location }
}) => {

    //Scroll to top if pathname is of Home:
    if (location.hash === '') {
        window.scrollTo(0, 0)
    }

    return false
}