exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-confirm-tsx": () => import("./../../../src/pages/confirm.tsx" /* webpackChunkName: "component---src-pages-confirm-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jointeam-tsx": () => import("./../../../src/pages/jointeam.tsx" /* webpackChunkName: "component---src-pages-jointeam-tsx" */),
  "component---src-pages-mysubscription-tsx": () => import("./../../../src/pages/mysubscription.tsx" /* webpackChunkName: "component---src-pages-mysubscription-tsx" */),
  "component---src-pages-nutritionconsulting-tsx": () => import("./../../../src/pages/nutritionconsulting.tsx" /* webpackChunkName: "component---src-pages-nutritionconsulting-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-termsofservice-tsx": () => import("./../../../src/pages/termsofservice.tsx" /* webpackChunkName: "component---src-pages-termsofservice-tsx" */)
}

